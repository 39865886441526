import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from '@services/shared/notification.service';

@Component({
  selector: 'app-copyable-text',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [NotificationService],
  templateUrl: './copyable-text.component.html',
  styleUrl: './copyable-text.component.scss',
})
export class CopyableTextComponent {
  @Input() text: string = '';

  constructor(
    private clipboard: Clipboard,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  copyText() {
    const res = this.clipboard.copy(this.text);

    if (res) {
      this.notificationService.success(
        this.translateService.instant('APP.COPIED'),
      );
    }
  }
}
